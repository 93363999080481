import { writable } from 'svelte/store';

// Export the store so we can use it in any component
export const confirmDialog = writable(null);

export async function confirm(message, title = '', mods = {}) {
	let resolve;

	const promise = new Promise((_resolve) => {
		resolve = _resolve;
	});

	/**
	 *  Possible Mods:
	 *  - confirmText: string
	 *  - cancelText: string
	 *  - confirmButtonClass: string
	 *  - cancelButtonClass: string
	 */

	confirmDialog.set({
		message,
		title,
		mods,
		resolve,
	});

	return promise;
}
